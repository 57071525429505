import * as React from 'react'
import { Link, graphql } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import SEO from '../components/SEO'
import Button from '@material-ui/core/Button';

import { IxForm } from "@intalex/gatsby-theme-base"

import { colors, breakpoints } from '../styles/variables'
import { StaticImage } from 'gatsby-plugin-image'
import { Button as StyledButton } from '../common/styledComponents'
import { makeStyles } from "@material-ui/core/styles";

const sm = `@media (min-width: ${breakpoints.sm}px)`
const md = `@media (min-width: ${breakpoints.md}px)`
const lg = `@media (min-width: ${breakpoints.lg}px)`
const xl = `@media (min-width: ${breakpoints.xl}px)`
const mdStyle = `@media (minWidth: ${breakpoints.md}px)`

import handshakeSvg from "../images/process/Handshake.svg";
import magnifyingGlassSvg from "../images/process/Magnifying Glass.svg";
import buildingBlocksSvg from "../images/process/Building Blocks.svg";
import scienceBeakerSvg from "../images/process/Science Beaker.svg";
import cogsSvg from "../images/process/Cogs.svg";

import infographic1 from "../images/infographics/Statistics 1.svg";
import infographic2 from "../images/infographics/Statistics 2.svg";
import infographic3 from "../images/infographics/Statistics 3.svg";

const styles = makeStyles((theme) => ({
  heroContent: {
    padding: "4rem 2rem",
    [md]: {
      marginLeft: "-25%",
      padding: 0,
    },
    [lg]: {
      marginLeft: "-28%",
    }
  },
  heroHeader: {
    color: colors.white,
    fontWeight: "normal",
    marginBottom: "3rem",

    [md]: {
      fontSize: "3.2rem",
    }
  },
  highPerformaceWebsitesContentContainer: {
    maxHeight: "800px"
    /*marginRight: "-70%",
    [md]: {
      marginRight: "-60%",
    },
    [lg]: {
      marginRight: "-36%",
    },
    [xl]: {
      marginRight: "-50%",
    }*/
  },
  highPerformanceWebsitesContent: {
    zIndex: 10,
    maxWidth: "200px",
    marginLeft: "40%",
    padding: "2rem 0",
    [sm]: {
      maxWidth: "300px",
      //marginLeft: "-20%",
    },
    [md]: {
      maxWidth: "300px",
      //marginLeft: "-12%",
    },
    [lg]: {
      maxWidth: "335px",
      //marginLeft: "40%",
    },
    [xl]: {
      maxWidth: "525px",
    }
  },
  highPerformanceWebsitesParagraph: {
    textAlign: "center",
    fontSize: "1.1em",
    [md]: {
      fontSize: "1.3em",
    },
    [lg]: {
      fontSize: "1.6em",
    },
    [xl]: {
      fontSize: "1.6em",
    }
  },
  highPerformanceImageAdjustment: {
    marginRight: "-20%",
    [sm]: {
      marginRight: "0",
    }
  },
  websitesHeading: {
    textAlign: "center",
    color: colors.white,
    fontWeight: 500,
    fontSize: "2rem",
    marginBottom: "1rem",
    [md]: {
      fontSize: "2.8rem",
    },
    [lg]: {
      fontSize: "3.3rem",
      marginBottom: "2rem",
    },
    [xl]: {
      fontSize: "3.8rem",
      marginBottom: "3rem",
    }
  },
  processTextPadding: {
    padding: "1rem",
    fontSize: "1.1rem",
    [md]: {
      padding: "1rem 3rem",
      fontSize: "1.3rem",
    },
    [lg]: { padding: "1rem 6rem" },
  },
  actionButton: {
    backgroundColor: "transparent",
    fontSize: "1rem",
    [md]: { fontSize: "1.5rem" },

    '&:hover': {
      backgroundColor: colors.intalexGreen,
    },
  },
  ourProcessSection: {
    backgroundColor: colors.lightGrey,
    padding: "3rem 1rem",
    color: colors.darkGrey,
    fontSize: "1.2em",
    textAlign: "center",

    [md]: {
      padding: "3rem",
      fontSize: "1.4em",
    },
    [lg]: {
      fontSize: "1.6em",
    }
  },
  processTile: {
    width: "100px",
    fontSize: "1.2rem",
    margin: "0 20px",

    [md]: {
      margin: "0 35px",
    },
    [lg]: {
      margin: "0 60px",
    }
  },
  businessWebsitesSection: {
    backgroundColor: colors.white,
    margin: "0",
    color: colors.darkGrey,
    textAlign: "center",
    padding: "3rem 1rem",
    fontSize: "1.2rem",

    [md]: {
      fontSize: "1.5em",
      padding: "3rem",
    }
  },
  businessWebsitesContentContainer: {
    display: "flex",
    flexDirection: "column",

    [md]: { flexDirection: "row" }
  },
  businessWebsitesContent: {
    textAlign: "left",
    fontSize: "1.1rem",

    [md]: {
      marginTop: "40px",
      marginLeft: "20px",
      fontSize: "1.4rem",
    },
    [lg]: {
      fontSize: "1.6rem"
    }
  },
  infographicContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",

    [md]: {
      flexDirection: "column",
      flexWrap: "none",
      marginTop: "-30px"
    },
    [lg]: {
      marginTop: "-60px"
    }
  },
  infographic: {
    display: "flex",
    margin: "20px 10px",
    //padding: "5px 10px",
    //border: `1px solid #ddd`,
    //borderRadius: "8px",
    width: "200px",

    [md]: {
      margin: "20px 40px",
    }
  },
  ourMissionContentContainer: {
    padding: "3rem",
    [sm]: {
      marginLeft: "-10%",
      padding: 0,
    },
    [md]: {
      marginLeft: "-20%",
    },
    [lg]: {
      marginLeft: "-35%",
    },
    [xl]: {
      marginLeft: "-45%",
    }
  },
  ourMissionContent: {
    maxWidth: "420px"
  },
  ourMissionHeader: {
    //fontWeight: "500",
    color: colors.white,
    fontSize: "3.2em",
    marginBottom: "1em",
    textAlign: "center",
  },
  ourMissionText: {
    color: colors.white,
    fontSize: "1.1em",
    //marginBottom: "4em",
    textAlign: "center",

    [md]: {
      fontSize: "1.3em",
    },
    [lg]: {
      fontSize: "1.5em",
    }
  },
  overlayedGridImage: {
    gridArea: "1/1",
  },
  overlayedGridContent: {
    // By using the same grid area for both, they are stacked on top of each other
    gridArea: "1/1",
    position: "relative",
    // This centers the other elements inside the hero component
    placeItems: "center",
    display: "grid"
  },
  centeredCards: {
    display: "flex",
    justifyContent: "center",
    flexFlow: "wrap",
  },
  clientCards: {
    display: "flex",
    justifyContent: "center",
    flexFlow: "wrap",

    [lg]: {
      justifyContent: "normal",
      marginLeft: "3rem",
    }
  },
  sectionHeading: {
    fontWeight: "bold",
    fontSize: "3.5rem",
    margin: "16px"
  },
  enquireButton: {
    backgroundColor: "rgba(16, 136, 83, 0.3)",
    textTransform: "none",
    marginTop: "10px",
    padding: "5px 30px",

    '&:hover, &:focus': {
      backgroundColor: "rgb(16, 136, 83)",
    }
  }
 
}));


export const query = graphql`
query {
      enquiryForm:allFormlexForm(filter: {name: {eq: "Enquiry"}}) {
        nodes {
            dateCreated
            formId
            formFields {
              fieldId
              fieldType
              mandatory
              multiline
              name
              sortIndex
              validationRegex
            }
            name
            status {
              name
            }
        }
      }

      site {
        siteMetadata {
          formlex {
            returnPath
            submissionsUrl
          }
          siteUrl
        }
      }
}

`;

const IndexPage = ({ data }) => {
  const classes = styles();
  const site = data.site;
  const enquiryForm = data.enquiryForm.nodes[0];
  console.log(enquiryForm);
  const returnUrl = `${site.siteMetadata.siteUrl}/${site.siteMetadata.formlex.returnPath}`
  const submissionsUrl = site.siteMetadata.formlex.submissionsUrl

  return (
    <IndexLayout>
      <SEO title='' description='Intalex creates modern, high performance software and web applications for business. At Intalex we make ideas become a reality.' />
      <Page style={{ padding: "0" }}>
        <Container >

          <div style={{ display: "grid" }}>
            {/* You can use a GatsbyImage component if the image is dynamic */}
            <StaticImage
              style={{
                maxHeight: 720,
                marginTop: "-60px",
                zIndex: -5
              }}
              className={classes.overlayedGridImage}
              layout="fullWidth"
              // This is a presentational image, so the alt should be an empty string
              alt=""
              src={
                "../images/hero_bg.jpg"
              }
              formats={["auto", "webp", "avif"]}
            />
            <div className={`${classes.heroContent}  ${classes.overlayedGridContent}`}>
              {/* Any content here will be centered in the component */}
              <div style={{ maxWidth: "500px" }}>
                <h1 className={classes.heroHeader}>Modern, high performance software for business.</h1>
                <p style={{ color: colors.white, fontSize: "1.5rem", fontStyle: "italic", fontWeight: "lighter" }}>We make ideas become a reality</p>
              </div>
            </div>
          </div>
        </Container>


        <Container style={{ backgroundColor: colors.darkGrey, color: colors.white }}>
          <div style={{ display: "grid", maxHeight: "800px", overflow: "hidden" }}>
            {/* You can use a GatsbyImage component if the image is dynamic */}
            <StaticImage
              style={{
                zIndex: 1,
                //marginLeft: "-20%",
                [mdStyle]: {
                  marginLeft: "0",
                },
                //maxHeight: "800px",
              }}
              className={`${classes.overlayedGridImage} ${classes.highPerformanceImageAdjustment}`}
              layout="fullWidth"
              // This is a presentational image, so the alt should be an empty string
              alt=""
              src={
                "../images/hero_2_bg_darker.jpg"
              }
              formats={["auto", "webp", "avif"]}
            />
            <div className={`${classes.highPerformaceWebsitesContentContainer} ${classes.overlayedGridContent}`}>
              {/* Any content here will be centered in the component */}
              <div className={classes.highPerformanceWebsitesContent}>
                <h2 className={classes.websitesHeading}>Intalex Platform</h2>
                <p className={classes.highPerformanceWebsitesParagraph}>
                  Our platform isn’t your traditional website platform. <br />
                  It’s a modern, serverless, cloud first
                  website delivery network that gives you a streamlined experience from start to finish.<br />
                  It’s modern made simple!<br />
                </p>
                <p className={classes.highPerformanceWebsitesParagraph}>
                  <StyledButton
                    to="/contact/"
                    className={classes.actionButton}
                  >
                    Sound Interesting?
                </StyledButton>

                </p>
                {/*<ButtonPrimary
                to="/products/microservices/"
              >
                Learn More
              </ButtonPrimary>*/}
              </div>
            </div>
          </div>
        </Container>

        <Container className={classes.ourProcessSection}>
          <h2 style={{ color: colors.black }} className={classes.sectionHeading}>Intalex software</h2>
          <div className={classes.processTextPadding}>
            <p>
              At Intalex we build high performance, cloud first enterprise grade
              software solutions for businesses.
          </p>
            <p>
              Intalex is dedicated to building flexible software, using modern development practices, so that it can easily scale to suit your business needs.
          </p>
            <p>
              Security is important to us, that’s why at Intalex our developers stay up-to-date with industry news and advice.
              </p>
            <p>
              <Link to="/contact/" style={{ color: colors.secOrange, fontWeight: 500, fontSize: "1.5rem" }}>Talk to us today</Link> to see how we can help with your next project.
            </p>
          </div>
          <h3>Our Process</h3>

          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", margin: "20px" }}>
              <div className={classes.processTile}>
              <img src={handshakeSvg} style={{ width: "80px", height: "80px" }} alt="Handshake"/>

                  <p>
                    Consult & Collaborate
                  </p>
                </div>
              <div className={classes.processTile}>
                <img src={magnifyingGlassSvg} style={{ width: "80px", height: "80px" }} alt="Magnifying Glass" />

                <p>
                  Discovery & Design
                </p>
              </div>
            <div className={classes.processTile}>
                <img src={buildingBlocksSvg} alt="Building Blocks" style={{ width: "80px", height: "80px" }} />

                <p>
                  Code & Construct
                </p>
              </div>
              <div className={classes.processTile}>
                <img src={scienceBeakerSvg} style={{ width: "80px", height: "80px" }} alt="Handshake" />

                <p>
                  Test & Tweak
                </p>
              </div>
              <div className={classes.processTile}>
              <img src={cogsSvg} style={{ width: "80px", height: "80px" }} alt="Cogs" />

                <p>
                  Integrate & Install
                </p>
              </div>
            </div>
        </Container>

        <Container className={classes.businessWebsitesSection}>
          <h2 style={{ color: colors.intalexGreen }} className={classes.sectionHeading}>Intalex business websites</h2>
          <h3 style={{ fontSize: "2rem" }}>The new way to build websites</h3>
          <div className={classes.businessWebsitesContentContainer}>
            <div className={classes.infographicContainer}>
              <div className={classes.infographic}>
                <img src={infographic1} style={{ width: "200px", height: "175px" }} alt="57% of people won't recommend a business with a poor website" />
              </div>
              <div className={classes.infographic}>
                <img src={infographic2} style={{ width: "200px", height: "175px" }} alt="72.9% of all online purchases are through mobile eCommerce" />
              
              </div>
              <div className={classes.infographic}>
                <img src={infographic3} style={{ width: "200px", height: "175px" }} alt="50ms is all it takes for user to form an opinion of your website" />
               
              </div>
            </div>
            <div className={classes.businessWebsitesContent}>
              <p>
                You never get a second chance to make a first impression!
              </p>
              <p>
                In today's digital world, a website can create a lasting first impression.
                The digital world is ever-changing and that's why it's important for your business to stay up to date with modern technology.

              </p>

              <p>
                A professionally designed and managed website is crucial to growing your business. At Intalex, being software developers, we have a thorough understanding of what it takes to architect a reliable and secure website solution. We use modern development practises to ensure your website is scalable and robust.
                </p>
              <p>
                No one-size fits all! Our friendly team will help find a perfect balance of flexibility and customisation to suit your budget.
              </p>
              <p>
                <Link to="/contact/" style={{ color: colors.intalexGreen, fontWeight: 500 }}>Lets work together</Link> to build your new website.
              </p>
              </div>
            </div>
        </Container>


        <Container css={{ backgroundColor: "#888" }}>
          <div style={{ display: "grid" }}>
            {/* You can use a GatsbyImage component if the image is dynamic */}
            <StaticImage
              style={{
                // You can set a maximum height for the image, if you wish.
                maxHeight: 600,
                zIndex: 1
              }}
              className={classes.overlayedGridImage}
              layout="fullWidth"
              // This is a presentational image, so the alt should be an empty string
              alt=""
              src={
                "../images/our-mission.jpg"
              }
              formats={["auto", "webp", "avif"]}
            />
            <div style={{ zIndex: 10 }} className={`${classes.ourMissionContentContainer} ${classes.overlayedGridContent}`}>
              {/* Any content here will be centered in the component */}
              <div className={classes.ourMissionContent}>
                <h2 className={classes.ourMissionHeader}>Our Mission</h2>
                <p className={classes.ourMissionText}>
                  At Intalex, our mission is to make <br />
high quality modern websites and software accessible to all businesses.
                </p>
              </div>
            </div>
          </div>
        </Container>




        {/* <Container css={{ padding: "1rem 0rem", margin: "3rem 0", backgroundColor: "#fff" }}>
          <h2 style={{ fontWeight: "bold", color: colors.midGreen, padding: "0 3rem" }}>Our Clients</h2>
        
         

          <div className={classes.clientCards}>
            <Card css={{ backgroundColor: "#fff" }}>
              <CardImage>
                <img alt="CMYKhub" />
              </CardImage>
              <CardBody>
                <p>
                  CMYKhub are Australias largest Print Manufacturer
                </p>
                <p>
                  <em>Craig Graham</em>
                </p>
              </CardBody>
            </Card>
            <Card css={{ backgroundColor: "#fff" }}>
              <CardImage>
                <img alt="Paddle WA" />
              </CardImage>
              <CardBody>
                <p>
                  Paddle WA are the peak body for Paddling in Western Australia
                </p>
                <p>
                  <em>Rosalie Evans</em>
                </p>
              </CardBody>
            </Card>

          </div>
        </Container>


        <Container css={{ padding: "1rem 0", margin: "3rem 0", backgroundColor: "#333", textAlign: "center" }}>
          <h2 style={{ fontWeight: "bold", color: colors.white }}>Meet Our Team</h2>
          <p css={{ color: colors.white, fontSize: "1.2em", padding: "0 3rem" }}>
            Our dedicated team is based in Perth and are trustworthy, professional and friendly.<br />
            With more than a decade of experience, our team are easy to talk to and will bring to life an engaging website that will help you grow your business.
          </p>

          <div className={classes.centeredCards}>

            <Card css={{ backgroundColor: "#444", color: colors.white }}>
              <CardImage>
                <img alt="Scott" />
              </CardImage>
              <CardBody>
                <h3 css={{ color: colors.white }}>Scott</h3>
                <p>
                  The Big Boss
                </p>
              </CardBody>
            </Card>

            <Card css={{ backgroundColor: "#444", color: colors.white }}>
              <CardImage>
                <img alt="Beck" />
              </CardImage>
              <CardBody>
                <h3 css={{ color: colors.white }}>Beck</h3>
                <p>
                  Makes Everything Happen
                </p>
              </CardBody>
            </Card>

            <Card css={{ backgroundColor: "#444", color: colors.white }}>
              <CardImage>
                <img alt="Ben" />
              </CardImage>
              <CardBody>
                <h3 css={{ color: colors.white }}>Ben</h3>
                <p>
                  Web Development Expert
                </p>
              </CardBody>
            </Card>

          </div>
        </Container> */}

        <Container css={{ padding: "1rem 3rem", margin: "3rem 0", backgroundColor: "#fff", textAlign: "center" }}>
          <h2 style={{ color: "#10603A" }} id="contact">Is your website delivering the value you need?<br />Request a free consultation with our team.</h2>
          {/*<form>
            <input name="Your Name" placeholder="Your Name" />
            <input type="submit" value="Start" />
          </form>*/}
          {/*enquiryForm && (
            <IxForm form={enquiryForm} returnUrl={returnUrl} submissionsUrl={submissionsUrl} className="enquiryFormStyles" submitText="enquire" />
          )*/}
          <Button href={"/contact/"} color="primary" className={classes.enquireButton}>enquire</Button>
        </Container>
      </Page>
    </IndexLayout>
  )
}

export default IndexPage
