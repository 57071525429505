import { styled } from '@material-ui/core/styles';
import { Link } from 'gatsby'
import { colors } from '../styles/variables'

export const ButtonPrimary = styled(Link)({
  color: colors.white,
  fontSize: "1.0rem",
  //fontWeight: '400',
  backgroundColor: colors.midGreen,
  marginTop: '20px',
  padding: '8px 25px',
  display: 'inline-block',

  '&:hover, &:focus': {
    textDecoration: 'none',
  }
})

export const Button = styled(Link)({
  color: colors.white,
  fontSize: '1.0rem',
  //font-weight: 400;
  padding: '8px 25px',
  border: `1px solid ${colors.white}`,
  transition: 'all 0.1s',

  '&:hover, &:focus': {
    textDecoration: 'none',
    backgroundColor: colors.intalexGreen,
  }
})
